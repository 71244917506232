<template lang="pug">
div.container
  div(v-if="!members")
    loading-spinner(message="Loading members...")
  div(v-if="members")
    div.row.justify-content-center.text-center
      h1 Membership Statuses
    div.row.justify-content-center.text-center.mb-5
      p.text-muted.col-md-8 Here you can search for all active members and expired individual members. We hide expired affiliate members because there's just too many to show!

    div.row.justify-content-center.text-center.user-search-box.mb-4
      b-form(inline)
        b-input(type="text" placeholder="Search" v-model="searchText" @input="resultLimit = 20")

    div.row.d-flex.justify-content-center
      div.col-lg-5.col-md-6.col-xl-4
        b-list-group
          b-list-group-item(active) Individual & Affiliate Members
          b-list-group-item.d-flex.justify-content-between.align-items-center(v-for="member in displayedMembers" :key="member._id")
            span {{member.firstName}} {{member.lastName}} <small class="text-muted">| {{member.igdaFinlandId}}</small>
            b-badge(variant="warning" v-if="member.membershipType == 'Lifetime'") <fa-icon icon="star"></fa-icon> Lifetime
            b-badge(variant="success" v-else-if="member.validUntil > expiryThreshold") <fa-icon icon="check"></fa-icon> {{member.membershipType}}
            b-badge(variant="success" v-else-if="member.validUntil > now") <fa-icon icon="clock"></fa-icon> {{member.membershipType}}
            b-badge(variant="secondary" v-else) Expired
        p.text-center {{memberSearchResults.length}} search results
          |
          |
          span(v-show="displayedMembers.length < memberSearchResults.length") ({{displayedMembers.length}} shown)

    div.row.justify-content-center
      b-button(v-if="displayedMembers.length < memberSearchResults.length" size="sm" variant="info" @click="loadMore()") Load more
</template>

<script>
export default {
  data: function () {
    return {
      members: [],
      searchText: '',
      resultLimit: 20,
      now: new Date(),
      expiryThreshold: new Date()
    }
  },
  computed: {
    memberSearchResults: function () {
      return this.members.filter(member => {
        if (this.searchText == null) return false // Avoid null refs...
        let s = this.searchText.toLowerCase()
        let hit = false

        // These could be cached but does it matter?
        let lastAndFirst = member.lastName + ' ' + member.firstName
        let firstAndLast = member.firstName + ' ' + member.lastName

        if (firstAndLast.toLowerCase().includes(s)) hit = true
        else if (lastAndFirst.toLowerCase().includes(s)) hit = true

        return hit
      })
    },
    displayedMembers: function () {
      return this.memberSearchResults.slice(0, this.resultLimit)
    }
  },
  methods: {
    loadMore: function () {
      this.resultLimit += 20
    },
    sortByName: function (a, b) {
      if (!a.hasOwnProperty('firstName')) return 1
      if (!b.hasOwnProperty('firstName')) return -1
      let nameA = a.firstName.toLowerCase()
      let nameB = b.firstName.toLowerCase()
      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0
    }
  },
  mounted: function () {
    this.expiryThreshold = new Date(this.expiryThreshold.setFullYear(this.now.getFullYear(), this.now.getMonth() + 2, 0))

    Promise.all([this.axios.get('/api/individual-members/cards'), this.axios.get('/api/affiliates/valid')])
      .then(responses => {
        for (let m of responses[0].data) m.validUntil = new Date(m.validUntil)
        this.members = responses[0].data

        for (let a of responses[1].data) {
          for (let m of a.members) {
            a.validUntil = new Date(a.validUntil)
            a.createdDate = new Date(a.createdDate)
            this.members.push({
              firstName: m.firstName,
              lastName: m.lastName,
              igdaFinlandId: a.memberIdPrefix + a.createdDate.getFullYear().toString().slice(2) + '-' + m.idSuffix,
              validUntil: a.validUntil,
              membershipType: 'Affiliate'
            })
          }
        }

        this.members.sort(this.sortByName)
      })
      .catch(err => {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Fetching members failed',
          text: err.response.data,
          duration: -1
        })
      })
  }
}
</script>
